@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter;
  src: url(./assets/font/Inter.ttf);
}

body {
  font-family: Inter;
  background-color: #0c0b11;
  color: white;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #666666;
}

::-webkit-scrollbar-thumb {
  background: #919cff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #919cff;
}

.myContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 250px;
  padding-right: 250px;
}

@media (max-width: 1380px) {
  .myContainer {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 768px) {
  .myContainer {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (max-width: 640px) {
  .myContainer {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.text-gradient {
  background: -webkit-linear-gradient(left, #d591ff, #919cff, #91ebff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blinking {
  animation: blinker 3s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
