@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.scroll-container {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.scroll-content {
  display: flex;
  animation: scroll 40s linear infinite;
}
