.sidebarOpen {
  width: 80px;
  transition: width 0.2s;
}

.sidebarClose {
  width: 0px;
  transition: width 0.2s;
}

.arrowPositionOpen {
  left: 56px;
  transition: left 0.2s;
}

.arrowPositionClose {
  left: -15px;
  transition: left 0.2s;
}
